import { toFormData } from 'axios'
import { endOfDay } from 'date-fns'

import { axiosInstance } from '@/shared/api'
import { getUserUrl } from '@/shared/api/config/api.config'
import { IPaginationData, TypePaginationDataWithFilters } from '@/shared/types/global.types'
import { ISport } from '@/shared/types/sport.types'
import { IUsers } from '@/shared/types/user.types'

const fromDateDefault = new Date(new Date().setDate(new Date().getDate() - 30))

const UserService = {
  async changePassword(data: IUsers.IChangePasswordData) {
    return axiosInstance.post<{ message: string }>(getUserUrl('/password-change'), data, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
  },

  async getRatingScores(id: number, rewardType: string) {
    return axiosInstance.post<string>(getUserUrl(`/reward/${id}?rewardtype=${rewardType}`))
  },

  async getLeaderboard({ page = 1, count = 21 }: IPaginationData) {
    return axiosInstance.get<IUsers.ILeaderBoardResponse>(
      getUserUrl(`/leaderboard?page=${page}&count=${count}`)
    )
  },

  async editUserData(data: IUsers.TypeChangeUserData) {
    return axiosInstance.patch(getUserUrl('/edit'), data, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
  },

  async sendUserCode(email: string) {
    return axiosInstance.patch(
      getUserUrl('/send_email_activate_code'),
      toFormData({
        email
      })
    )
  },

  // заменил IPaginationData на TypePaginationDataWithFilters
  async getBuysList({
    page = 1,
    count = 21,
    fromDate,
    toDate = new Date(),
    show
  }: TypePaginationDataWithFilters) {
    return axiosInstance.get<IUsers.IPaymentHistory>(getUserUrl(`/buys`), {
      params: {
        page,
        count,
        fromDate: fromDate?.toISOString(),
        toDate: toDate?.toISOString(),
        show: show
      }
    })
  },

  async getBetsList({
    page = 1,
    count = 5,
    fromDate,
    toDate = new Date(),
    show
  }: TypePaginationDataWithFilters) {
    return axiosInstance.get<ISport.IResponseExpressList>(getUserUrl(`/bet/express_list`), {
      params: {
        page,
        count,
        show,
        fromDate: fromDate?.toISOString(),
        toDate: endOfDay(toDate).toISOString()
      }
    })
  },

  async getBetsExpertsList({
    fromDate,
    toDate = new Date(),
    ...restParams
  }: IUsers.GetBettingExpert) {
    return axiosInstance.get<ISport.IExpressList[]>(getUserUrl(`/bet/expert_bets_list`), {
      params: {
        fromDate: fromDate?.toISOString(),
        toDate: endOfDay(toDate).toISOString(),
        ...restParams
      }
    })
  },

  async getBonuses() {
    return axiosInstance.get<string>(getUserUrl(`/me/bonus`))
  },

  async getDeposit({ method, count }: IUsers.IGetDeposit) {
    return axiosInstance.post<string>(getUserUrl(`/deposit?method=${method}&CNY=${count}`))
  },

  async getReferralLink() {
    return axiosInstance.get<{ url: string }>(getUserUrl(`/get_referral_link`))
  },

  async getReferralUsers() {
    return axiosInstance.get<{ url: string }>(getUserUrl(`/get_referral_list`))
  }
}

export default UserService
