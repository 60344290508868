// import { axiosClassic as axios } from '@/shared/api'
import Cookies from 'js-cookie'

import { axiosInstance as axios } from '@/shared/api'
import { API_URL } from '@/shared/api/config/api.config'

const register = (form: FormData, ref?: string) => {
  return axios.post(API_URL + 'user/register', form, { params: ref })
}

const activate = (form: FormData) => {
  return axios.post(API_URL + 'user/activate', form)
}

const login = (form: FormData) => {
  return axios.post(API_URL + 'user/access', form)
}

const logout = () => {
  localStorage.removeItem('refresh')
  localStorage.removeItem('access')
  Cookies.remove('refreshToken')
  Cookies.remove('accessToken')
  window.location.reload()
}

export default {
  register,
  login,
  logout,
  activate
}
