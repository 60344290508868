import { FC, useEffect, useState } from 'react'

import { eachYearOfInterval, endOfYear, getYear, startOfYear } from 'date-fns'

import { LeftArrowGradientJSX, RightArrowGradientJSX } from '@/shared/assets/common/svg'
import { useIsMobile, useTranslationField } from '@/shared/hooks'
import { TValuesLocality } from '@/shared/types/global.types'

import { ReactComponent as ArrowLeftIcon } from '@/Assets/svg/arrow-left.svg'
import { ReactComponent as ArrowRightIcon } from '@/Assets/svg/arrow-right.svg'

import { Dropdown } from '../../dropdown'
import { MyOptionType } from '../form.interface'

import { CustomMobileYearAndMonthSwiperModal } from './CustomMobileYearAndMonthSwiperModal'
import { ICustomHeader } from './calendar.interface'

const months = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december'
]

const CustomHeader: FC<ICustomHeader> = ({
  date,
  decreaseMonth,
  increaseMonth,
  changeYear,
  changeMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled
}) => {
  const { getFieldStatic } = useTranslationField()
  const { isMobile } = useIsMobile()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const currentYear = getYear(new Date())
  const startYear = 1900
  const startOfYearDate = startOfYear(new Date(startYear, 0, 1))
  const endOfYearDate = endOfYear(new Date(currentYear, 0, 1))
  const [yearOptions, setYearOptions] = useState<MyOptionType[]>([])

  useEffect(() => {
    const yearsArray = eachYearOfInterval({
      start: startOfYearDate,
      end: endOfYearDate
    })
      .map(year => ({
        label: getYear(year).toString(),
        value: getYear(year).toString()
      }))
      .reverse()
    setYearOptions(yearsArray)
  }, [])

  const onChangeYear = (item: MyOptionType) => {
    changeYear(+item.value)
  }

  const openMobileModal = () => {
    isMobile && setIsModalOpen(true)
  }

  const closeMobileModal = () => {
    isMobile && setIsModalOpen(false)
  }

  return (
    <div className='custom-header-datepicker'>
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <span>{getFieldStatic(months[date.getMonth()] as TValuesLocality)}</span>
        {isMobile ? (
          <>
            <div className='custom-header-datepicker-year' onClick={openMobileModal}>
              {date.getFullYear().toString()}
              <RightArrowGradientJSX />
            </div>
            <CustomMobileYearAndMonthSwiperModal
              isModalOpen={isModalOpen}
              closeModal={closeMobileModal}
              date={date}
              changeYear={changeYear}
              changeMonth={changeMonth}
              decreaseMonth={decreaseMonth}
              increaseMonth={increaseMonth}
              prevMonthButtonDisabled={prevMonthButtonDisabled}
              nextMonthButtonDisabled={nextMonthButtonDisabled}
              yearOptions={yearOptions}
            />
          </>
        ) : (
          <Dropdown
            options={yearOptions}
            defaultValue={date.getFullYear().toString()}
            onSelect={onChangeYear}
          />
        )}
      </div>
      <div className='custom-header__arrows'>
        <button type='button' onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
          {isMobile ? (
            <LeftArrowGradientJSX />
          ) : (
            <ArrowLeftIcon className='arrow-icon-datepicker' />
          )}
        </button>

        <button type='button' onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
          {isMobile ? (
            <RightArrowGradientJSX />
          ) : (
            <ArrowRightIcon className='arrow-icon-datepicker' />
          )}
        </button>
      </div>
    </div>
  )
}

export default CustomHeader
