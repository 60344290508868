import { FC, useRef, useState } from 'react'

import clsx from 'clsx'
import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'swiper/css/pagination'
import { EffectCoverflow, Virtual } from 'swiper/modules'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'

import {
  DownArrowGradientJSX,
  LeftArrowGradientJSX,
  RightArrowGradientJSX
} from '@/shared/assets/common/svg'
import { useTranslationField } from '@/shared/hooks'
import { TValuesLocality } from '@/shared/types/global.types'

import { Modal } from '../../modal'
import { TModalModifers } from '../../modal/modal.type'

import './Calendar.scss'
import styles from './CustomInput.module.scss'
import { ICustomMobileYearAndMonthSwiper } from './calendar.interface'

const months = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december'
]

export const CustomMobileYearAndMonthSwiperModal: FC<ICustomMobileYearAndMonthSwiper> = ({
  date,
  changeYear,
  changeMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  isModalOpen,
  closeModal,
  yearOptions
}) => {
  const { getFieldStatic } = useTranslationField()
  const [month, setMonth] = useState(date.getMonth())
  const [year, setYear] = useState(+date.getFullYear().toString())
  const isEmpty = Array.isArray(date) ? date.some(item => !item) : !date

  const modifers = ['simple', 'deposit'] as TModalModifers[]

  const onApply = () => {
    changeYear(year)
    changeMonth && changeMonth(month)
    closeModal()
  }

  const onClear = () => {
    changeYear(+date.getFullYear().toString())
    changeMonth && changeMonth(date.getMonth())
    closeModal()
  }

  const swiperRef = useRef<SwiperClass | null>(null)

  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext()
    }
  }

  const handlePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev()
    }
  }

  const onIncreaseMonth = () => {
    month < 11 && setMonth(month + 1)
    handleNext()
  }
  const onDecreaseMonth = () => {
    month > 0 && setMonth(month - 1)
    handlePrev()
  }

  const getInitialSlideYear = () => {
    const initialIndex = yearOptions.findIndex(el => el.value === date.getFullYear().toString())

    return initialIndex !== -1 ? initialIndex : 0
  }

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={closeModal}
      className={clsx(styles.modal)}
      disabledClickOutside
      modifers={modifers}
      withoutClose>
      <div className='react-datepicker-mobile-header react-datepicker-mobile-header-bottom-line'>
        <div className='react-datepicker-mobile-header'>
          <span>{getFieldStatic(months[month] as TValuesLocality)}</span>
          <span className='react-datepicker-mobile-header-year'>
            {year}
            <DownArrowGradientJSX />
          </span>
        </div>
        <div className='custom-header__arrows'>
          <button type='button' onClick={onDecreaseMonth} disabled={prevMonthButtonDisabled}>
            <LeftArrowGradientJSX className='arrow-icon-datepicker' />
          </button>
          <button type='button' onClick={onIncreaseMonth} disabled={nextMonthButtonDisabled}>
            <RightArrowGradientJSX className='arrow-icon-datepicker' />
          </button>
        </div>
      </div>
      <div className={'modalSwiperContainer'}>
        <Swiper
          className='month-swiper'
          onSlideChange={swiper => {
            setMonth(swiper.activeIndex)
          }}
          effect={'coverflow'}
          initialSlide={date.getMonth() || 0}
          onSwiper={swiper => (swiperRef.current = swiper)}
          grabCursor={true}
          slideToClickedSlide={true}
          centeredSlides={true}
          slidesPerView={5}
          direction={'vertical'}
          mousewheel={true}
          coverflowEffect={{
            rotate: 0,
            stretch: 1,
            depth: 50,
            modifier: 1,
            slideShadows: false
          }}
          pagination={true}
          virtual
          modules={[EffectCoverflow, Virtual]}>
          {months.map((el, index) => (
            <SwiperSlide id={`month-${el}`} key={el} virtualIndex={index}>
              {el}
            </SwiperSlide>
          ))}
        </Swiper>
        <Swiper
          onSlideChange={swiper => {
            setYear(+yearOptions[swiper.activeIndex].value)
          }}
          effect={'coverflow'}
          grabCursor={true}
          initialSlide={getInitialSlideYear()}
          centeredSlides={true}
          slidesPerView={5}
          direction={'vertical'}
          mousewheel={true}
          coverflowEffect={{
            rotate: 0,
            stretch: 1,
            depth: 50,
            modifier: 1,
            slideShadows: false
          }}
          pagination={true}
          modules={[EffectCoverflow]}>
          {yearOptions.map(el => (
            <SwiperSlide key={el.label}>{el.label}</SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className='react-datepicker-mobile-footer'>
        <div className={styles.icons}>
          <button className='button' onClick={onClear}>
            {!isEmpty && getFieldStatic('clear')}
          </button>
        </div>
        <div className='button'>
          <button className='button' onClick={closeModal}>
            {getFieldStatic('cancel')}
          </button>
          <button className='button' onClick={onApply}>
            {getFieldStatic('ok')}
          </button>
        </div>
      </div>
    </Modal>
  )
}
