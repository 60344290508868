/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'

import { FieldValues, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { TStepAuth } from '@/app/providers/protected-router'
import { login } from '@/app/store/actions/auth'

import { useTranslationField } from '@/shared/hooks'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { validEmail, validEmailOrUserId } from '@/shared/regex'
import { Button, FormWrapper, Input } from '@/shared/ui/form'
import { Loader } from '@/shared/ui/loader'

import '@/Assets/sass/components/Auth.sass'
import { ReactComponent as CloseIcon } from '@/Assets/svg/Auth/close-icon.svg'
import { ReactComponent as LoginIconCn } from '@/Assets/svg/Auth/login-icon-cn.svg'
import { ReactComponent as LoginIconEn } from '@/Assets/svg/Auth/login-icon-en.svg'

import { messages } from '@/i18n/messages'

interface IProps {
  page: (val: TStepAuth) => void
  widget?: boolean
}
export const Login: FC<IProps> = ({ page, widget = false }) => {
  const { getFieldStatic } = useTranslationField()
  const formLogin = useForm({ mode: 'onBlur' })
  const {
    formState: { errors }
  } = formLogin

  const { loader, locale } = useAppSelector(state => state.defaultReducer)

  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const onLogin = (values: FieldValues) => {
    const form = new FormData()

    //TODO костыль вне работы по авторизации
    if (validEmail.test(values.email)) {
      form.append('email', values.email)
      form.append('password', values.password)
    } else {
      form.append('user_id', values.email)
      form.append('password', values.password)
    }

    dispatch(login(form))
      .then(() => {})
      .catch(error => {})

    formLogin.reset({ email: '', password: '' })
  }

  if (loader) return <Loader />
  return (
    <div className='FormBlock'>
      <FormWrapper methods={formLogin} onSubmit={onLogin} className='LoginForm'>
        {locale === 'en' ? <LoginIconEn /> : <LoginIconCn />}
        <div className='formGroup'>
          <div className='close' onClick={() => (widget ? page('') : navigate(-1))}>
            <CloseIcon />
          </div>
          <div className='auth-block-wrap'>
            <div className='title'>{getFieldStatic('login')}</div>
            <div>
              <Input
                mask=''
                name='email'
                type='email'
                connect
                placeholder={getFieldStatic('user_id_email')}
                style={{ marginTop: '-5px' }}
                errorText={errors?.email?.message}
                registerConfig={{
                  required: messages[locale].required_field,
                  pattern: {
                    value: validEmailOrUserId,
                    message: getFieldStatic('correct_email_user_id')
                  }
                }}
              />
            </div>
            <div className='inputPass'>
              <Input
                mask=''
                name='password'
                type='password'
                connect
                isPassword
                placeholder={getFieldStatic('login_pass')}
                errorText={errors?.password?.message}
                registerConfig={{
                  required: messages[locale].required_field,
                  minLength: {
                    value: 8,
                    message: messages[locale].minimum_characters + ' - 8'
                  }
                }}
              />
            </div>
            <Button modifiers={['red', 'full']}>{getFieldStatic('login')}</Button>
          </div>

          <span onClick={() => page('registration')}>{getFieldStatic('register')}</span>
          <span onClick={() => page('forgot-password')} style={{ marginBottom: '30px' }}>
            {getFieldStatic('forgot_pass')}
          </span>
        </div>
      </FormWrapper>
    </div>
  )
}

export default Login
