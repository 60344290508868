import React, { useEffect } from 'react'

export const useDisabledScroll = (isDisabled?: boolean) => {
  useEffect(() => {
    if (isDisabled || isDisabled === undefined) {
      document.body.style.overflow = 'hidden'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isDisabled])
}
