import { KABAM_POSTBACK } from '@/app/hooks/useForwardPostBack'

import { removeTokensStorage, saveTokensStorage } from '@/shared/services/auth/auth.helper'
import PostBackService from '@/shared/services/postback.service'

import AuthService from '../services/auth.service'

//TODO ВЫнести с отдельный сервер при обновлении дизайна

// Экшн регистрации
export const register = (form: any, ref?: string) => (dispatch: any) => {
  return AuthService.register(form, ref).then(
    resp => {
      return resp
    },
    error => {
      const errorMessage = error?.response?.data?.detail || 'message_login_error'

      dispatch({
        type: 'REGISTER_FAIL'
      })
      dispatch({
        type: 'SET_MESSAGE',
        value: { type: 'info', message: errorMessage }
      })
      throw error
    }
  )
}

export const activate = (form: FormData) => (dispatch: any) => {
  return AuthService.activate(form).then(
    response => {
      const kabamInfo = JSON.parse(localStorage.getItem(KABAM_POSTBACK) || '{}')
      dispatch({
        type: 'REGISTER_SUCCESS'
      })
      dispatch({
        type: 'LOGIN_SUCCESS',
        value: response.data
      })
      saveTokensStorage(response.data)
      try {
        if (kabamInfo.subid) {
          const { subid, ...data } = kabamInfo
          PostBackService.sendPostBackKabam({ subid }).then(res => {
            localStorage.removeItem(KABAM_POSTBACK)
          })
        }
      } catch (error) {}

      return response
    },
    err => {
      const message = err?.response?.data?.detail || (err?.message as string)
      dispatch({
        type: 'REGISTER_FAIL'
      })

      dispatch({
        type: 'SET_MESSAGE',
        value: { type: 'info', message: message || 'message_register_error' }
      })
      return err
    }
  )
}

// Экшн авторизации
export const login = (form: any) => (dispatch: any) => {
  return AuthService.login(form).then(
    response => {
      dispatch({
        type: 'LOGIN_SUCCESS',
        value: response.data
      })
      dispatch({
        type: 'SET_MESSAGE',
        value: { type: 'info', message: 'message_login_success' }
      })
      saveTokensStorage(response.data)
      window.location.reload()
      return Promise.resolve()
    },
    error => {
      const errorMessage = error?.response?.data?.detail || 'message_login_error'
      dispatch({
        type: 'LOGIN_FAIL'
      })
      dispatch({
        type: 'SET_MESSAGE',
        value: { type: 'error', message: errorMessage }
      })
      removeTokensStorage()
      return Promise.reject()
    }
  )
}
