import { ChangeEvent, FC } from 'react'

import clsx from 'clsx'

import { CheckJSX } from '@/shared/assets/common/svg'

import styles from './RadioButton.module.scss'

interface IProps {
  title: string
  name: string
  icon?: string
  radioRef?: any
  value?: string
  onChange?: any
  additionalAction?: any
  onBlur?: any
  checked?: boolean
  classNameWrapper?: string
  isMobileSelector?: boolean
  type?: 'country' | 'phone' | 'radio'
}

export const RadioButton: FC<IProps> = ({
  title,
  name,
  radioRef,
  value,
  onChange,
  additionalAction,
  classNameWrapper,
  checked,
  isMobileSelector,
  type,
  icon,
  ...props
}) => {
  const onHandlerChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange!(e.target.value)
    type !== 'radio' && additionalAction!()
  }

  return (
    <div className={clsx(styles.wrapper, classNameWrapper)}>
      <label className={clsx(styles.label, isMobileSelector && styles.labelMobileSelector)}>
        <input
          className={clsx(styles.input)}
          ref={radioRef}
          name={name}
          type='radio'
          value={value}
          onChange={onHandlerChange}
          checked={checked}
          {...props}
        />
        {type === 'radio' && (
          <span className={clsx(styles.check, isMobileSelector && styles.checkMobileSelector)} />
        )}
        {type !== 'radio' && icon && (
          <div
            style={{
              width: '16px',
              height: '16px',
              borderRadius: '50%',
              overflow: 'hidden',
              backgroundImage: `url(https://flagcdn.com/w320/${String(icon).toLowerCase()}.png)`,
              backgroundSize: '150%',
              backgroundPosition: 'center center',
              display: 'inline-block'
            }}></div>
        )}
        <div className={clsx(isMobileSelector && styles.titleWrapperMobileSelector)}>
          <span
            className={clsx(
              styles.title,
              checked && styles.titleChecked,
              type !== 'radio' && styles.titleChecked
            )}>
            {title}
            {type === 'phone' && `  (${value})`}
          </span>
          {type !== 'radio' && checked && <CheckJSX />}
        </div>
      </label>
    </div>
  )
}
