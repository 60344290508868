import { ChangeEvent, FC, useEffect, useState } from 'react'

import cn, { clsx } from 'clsx'
import DatePicker from 'react-datepicker'
import 'react-datepicker/src/stylesheets/datepicker.scss'

import { useTranslationField } from '@/shared/hooks'
import useOutsideClick from '@/shared/hooks/useOutsideClick'

import { IPropCalendar } from '../form.interface'

import './Calendar.scss'
import CustomHeader from './CustomHeader'
import CustomInput from './CustomInput'
import styles from './CustomInput.module.scss'

export const Calendar: FC<IPropCalendar> = ({
  value,
  onChange,
  placeholder,
  inputClassName,
  calendarClassName,
  calendarRef,
  onBlur,
  withoutIcon,
  ...restProps
}) => {
  const [prevData, setPrevData] = useState(value)
  const { getFieldStatic, locale } = useTranslationField()

  const [isOpen, setIsOpen] = useState<boolean | undefined>()
  useEffect(() => {
    if (!isOpen) {
      setPrevData(value)
    }
  }, [isOpen, value])
  useEffect(() => {
    if (!isOpen) {
      setPrevData(value)
    }
  }, [isOpen, value])

  const onClose = () => {
    setIsOpen(false)
  }

  const { ref } = useOutsideClick(false, onClose)

  const onChangeCustomInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const date = new Date(Date.parse(value))
    onChange!(date)
  }

  const isEmpty = Array.isArray(value) ? value.some(item => !item) : !value

  const onChangeDate = (date: Date) => {
    onChange!(date)
    // setIsOpen(false)
  }

  const onClickLabel = () => {
    setIsOpen(true)
  }

  const onCancel = () => {
    onClose()
    //@ts-ignore
    onChange?.(prevData)
  }

  const onResetDate = () => {
    if (Array.isArray(value)) {
      //@ts-ignore
      onChange?.([undefined, undefined])
    } else {
      //@ts-ignore
      onChange?.(undefined)
    }
  }

  return (
    <div className={clsx('calendarContainer', styles.wrapper)} onBlur={onBlur} ref={ref}>
      <DatePicker
        ref={calendarRef}
        open={isOpen}
        autoComplete='off'
        locale={locale}
        showYearDropdown
        useWeekdaysShort
        {...(Array.isArray(value)
          ? {
              startDate: value[0],
              endDate: value[1]
            }
          : {
              selected: value
            })}
        onChange={onChangeDate}
        customInput={
          <CustomInput
            value={Array.isArray(value) ? value : value?.toISOString()}
            onClick={onClickLabel}
            onClickHandler={onClickLabel}
            placeholderText={placeholder}
            onChange={onChangeCustomInput}
            className={inputClassName}
            withoutIcon={withoutIcon}
          />
        }
        dateFormat='dd/MM/yyyy'
        placeholderText={placeholder}
        shouldCloseOnSelect={false}
        calendarClassName={cn('calendar', calendarClassName)}
        popperClassName='calendarPopout'
        {...restProps}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled
        }) => (
          <CustomHeader
            date={date}
            changeYear={changeYear}
            changeMonth={changeMonth}
            decreaseMonth={decreaseMonth}
            increaseMonth={increaseMonth}
            prevMonthButtonDisabled={prevMonthButtonDisabled}
            nextMonthButtonDisabled={nextMonthButtonDisabled}
          />
        )}>
        <div className={styles.footer}>
          <div className={styles.icons}>
            <button className={styles.button} onClick={onResetDate}>
              {!isEmpty && getFieldStatic('clear')}
            </button>
          </div>
          <div className={styles.buttons}>
            <button className={styles.button} onClick={onCancel}>
              {getFieldStatic('cancel')}
            </button>
            <button className={styles.button} onClick={onClose}>
              {getFieldStatic('ok')}
            </button>
          </div>
        </div>
      </DatePicker>
    </div>
  )
}
