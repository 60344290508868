import { useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { RegisterDemoUser } from '@/features/quiz/ui'

import { FINAL_QUIZ } from '@/shared/constants'
import { useLocalStorage } from '@/shared/hooks/useLocalStorage'

import { QuizLang } from '../slices/quiz_lang'

import { QuizOne } from './quiz_1'
import { QuizTwo } from './quiz_2'
import { QuizThree } from './quiz_3'
import { QuizFour } from './quiz_4'

const quizzes = [QuizLang, QuizOne, QuizTwo, QuizThree, QuizFour]

export const QuizVariant3 = () => {
  const navigate = useNavigate()
  const { setLocalStorageValue } = useLocalStorage(FINAL_QUIZ)
  const [isFinal, setIsFinal] = useState(false)
  const [step, setStep] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const onNextStep = () => {
    setStep(prev => prev + 1)
  }

  const onFinalStep = () => {
    if (isFinal) return
    setIsOpen(true)
    setIsFinal(true)
  }
  const onCloseModal = () => {
    setLocalStorageValue('on')
    navigate('/profile?tab=account-profile')
    setIsOpen(false)
  }

  const CurrentQuiz = quizzes[step]
  return (
    <>
      <CurrentQuiz onNextStep={onNextStep} onFinal={onFinalStep} />
      <RegisterDemoUser isOpen={isOpen} />
    </>
  )
}
