import { FC, PropsWithChildren } from 'react'

import clsx from 'clsx'

import styles from './Button.module.scss'

const mod = {
  gold: false,
  red: false,
  success: false,
  full: false,
  small: false,
  ultraSmall: false,
  inverse: false,
  textGold: false,
  textCapitalize: false,
  large: false
}

type TModifier = keyof typeof mod

type TProps = PropsWithChildren & {
  onClick?: () => void
  modifiers?: TModifier[]
  title?: string
  disabled?: boolean
  className?: string
  classNameContainer?: string
  type?: 'submit' | 'reset' | 'button'
}

export const Button: FC<TProps> = ({
  type = 'submit',
  children,
  onClick,
  modifiers = [],
  title,
  disabled,
  className,
  classNameContainer
}) => {
  const modList = { ...mod }

  modifiers.forEach(item => {
    modList[item] = true
  })

  return (
    <div className={clsx(styles.wrapper, classNameContainer)}>
      <button
        className={clsx(
          styles.button,
          className,
          disabled && styles.disabled,
          modList.success && styles.success,
          modList.gold && styles.gold,
          modList.red && styles.red,
          modList.small && styles.small,
          modList.ultraSmall && styles.ultraSmall,
          modList.textGold && styles.textGold,
          modList.textCapitalize && styles.textCapitalize,
          modList.full && styles.fullWidth,
          modList.inverse && styles.inverse,
          modList.large && styles.large
        )}
        data-content={title}
        onClick={onClick}
        disabled={disabled}
        type={type}>
        <div className={styles.text}>{children}</div>
      </button>
    </div>
  )
}
